import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'
import '@/assets/css/main.css'
import VueSocialSharing from 'vue-social-sharing'
import Toaster from "@meforma/vue-toaster";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueSocialSharing)
  .use(Toaster)

app.mount('#app')
