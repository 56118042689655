<template>
  <div v-show="!isContact">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Activités</h1>
      <button @click="this.$router.push('/admin/activities/add')" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Créer
      </button>
      <p v-if="successMessage" style="color: green">{{successMessage}}</p>
      <p v-if="errorMessage" style="color: red">{{errorMessage}}</p>
    </div>
    <div class=" mx-auto px-4 sm:px-6 md:px-8">
      <div class="py-4">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-1 lg:px-2">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th scope="col" class="pl-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Titre
                    </th>
                    <th scope="col" class="pl-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Contact
                    </th>
                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Participants
                    </th>
                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Votes
                    </th>
                    <th scope="col" class="pl-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Activité
                    </th>
                    <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Information
                    </th>
                    <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Suggestion
                    </th>
                    <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200" v-if="allActivities" v-for="activity in allActivities" :key="activity.id">
                    <tr @dlclick="onDblClick(activity)">
                      <td class="pl-6 py-3   text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <img v-if="
                            Math.floor((Date.now() - new Date(activity.create_at).getTime())/ (1000 * 3600 * 24))<7
                            && activity.activityStatus == false
                            && activity.informationStatus == false
                            && activity.suggestionStatus == false
                          "
                          src="/img/new.png" alt="new"
                          class="new"
                        >
                        {{ activity.type }}
                      </td>
                      <td class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{ activity.title }}
                      </td>
                      <td class="pl-6 py-3   text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        GSM: {{ activity.gsm }} <br/> EMAIL: {{ activity.email }}
                      </td>
                      <td @click="showActivityParticipants(activity._id)" class="px-6 py-3   text-center text-xs font-medium text-gray-500 uppercase tracking-wider acordion">
                        {{ activity.participants.length }}
                      </td>
                      <td class="px-6 py-3   text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{ activity.votes.length }}
                      </td>
                      <td class="px-6 py-3   text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <label :for="'toogleA'+activity._id" class="flex items-center cursor-pointer">
                            <!-- toggle -->
                            <div class="relative">
                              <!-- input -->
                              <input v-if="activity.activityStatus == true" @click='toggleActivityStatus(activity._id)' :id="'toogleA'+activity._id" type="checkbox" class="sr-only content-center" checked />
                              <input v-else @click='toggleActivityStatus(activity._id)' :id="'toogleA'+activity._id" type="checkbox" class="sr-only content-center" />
                              <!-- line -->
                              <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                              <!-- dot -->
                              <div  class="dot absolute w-6 h-6 bg-red-600 rounded-full shadow -left-1 -top-1 transition"></div>
                            </div>
                          </label>
                      </td>
                      <td class="px-6 py-3   text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <label :for="'toogleB'+activity._id" class="flex items-center cursor-pointer">
                          <!-- toggle -->
                          <div class="relative">
                            <!-- input -->
                            <input v-if="activity.informationStatus == true" @click='toggleInformationStatus(activity._id)' :id="'toogleB'+activity._id" type="checkbox" class="sr-only content-center" checked/>
                            <input v-else @click='toggleInformationStatus(activity._id)' :id="'toogleB'+activity._id" type="checkbox" class="sr-only content-center" />
                            <!-- line -->
                            <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <!-- dot -->
                            <div  class="dot absolute w-6 h-6 bg-red-600 rounded-full shadow -left-1 -top-1 transition"></div>
                          </div>
                        </label>
                      </td>
                      <td class="px-6 py-3   text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <label :for="'toogleC'+activity._id" class="flex items-center cursor-pointer">
                          <!-- toggle -->
                          <div class="relative">
                            <!-- input -->
                            <input v-if="activity.suggestionStatus == true" @click='toggleSuggestionStatus(activity._id)' :id="'toogleC'+activity._id" type="checkbox" class="sr-only content-center" checked />
                            <input v-else @click='toggleSuggestionStatus(activity._id)' :id="'toogleC'+activity._id" type="checkbox" class="sr-only content-center" />
                            <!-- line -->
                            <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <!-- dot -->
                            <div  class="dot absolute w-6 h-6 bg-red-600 rounded-full shadow -left-1 -top-1 transition"></div>
                          </div>
                        </label>
                        </td>
                        <td>
                            <router-link :to="{name: 'AdminPanelEditActivity', params: { id: activity._id }}">📝</router-link>
                            <router-link :to="{name: 'AdminPanelShowActivity', params: { id: activity._id }}">👁️‍🗨️</router-link>
                            <a class="cursor-pointer" @click="deleteActivity(activity._id)">🗑️</a>
                        </td>
                    </tr>
                    <transition name="scroll">
                    <tr v-if="activity.participants.length > 0 && this.showParticipants == activity._id" class="acordionBody">
                      <td colspan="9">
                        <form v-on:submit="contactUser(activity.participants.length)">
                          <table style="width:100%">
                            <tr class="acordionBody">
                              <th>
                                <button type="submit" class="mr-2 py-1 px-2 border border-transparent shadow-sm text-sm rounded-md text-white bg-gray-600 hover:bg-red-600">Send</button>
                              </th>
                              <th>
                                <input type="checkbox" id="all" name="all" v-on:click="selectAll">
                              </th>
                              <th>USERNAME</th>
                              <th>EMAIL</th>
                              <th>AGE</th>
                              <th>SEXE</th>
                              <th @click="closeShowActivityParticipants" class="cursor-pointer">X</th>
                            </tr>
                            <tr v-for="participant in activity.participants">
                              <td></td>
                              <td><input type="checkbox" :id="participant.pseudo" name="checkbox" :value="participant.email"></td>
                              <td>{{participant.pseudo}}</td>
                              <td>{{participant.email}}</td>
                              <td>{{participant.birthday}}</td>
                              <td>{{participant.sexe}}</td>
                            </tr>
                          </table>
                        </form>
                      </td>
                    </tr>
                    </transition>
                  </tbody>
                  <tbody v-else>
                    <div>
                      Nothing to show
                      <button @click="this.$router.push('/admin')" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Back to admin panel
                      </button>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="isContact">
    <form v-on:submit="sendEmail">

      <p for="message" class="block text-sm font-medium text-gray-700"><b>Sending message to: {{sendingTo}}</b></p>
      <div class="mt-1">
        <label for="subject" class="block text-sm font-medium text-gray-700">Sujet:</label>
        <input type="text" v-model="emailSubject" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
      </div>
      <div class="mt-1">
        <label for="message" class="block text-sm font-medium text-gray-700">Message:</label>
        <textarea v-model="message" required id="message" name="message" rows="4" class="py-3 px-4 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" style="width: 75%; margin: auto"/>
      </div>

      <button type="submit" class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
        Envoyer
      </button>
      <button type="button" @click="contactUser" class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
        Annuler
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import axios from 'axios'

export default {

  data () {
    return {
      allActivities: [],
      activityStatus: '',
      suggestionStatus: '',
      informationStatus: '',
      showParticipants: '',
      isContact: false,
      userEmail: [],
      sendingTo: '',
      emailSubject: '',
      message: '',
      errorMessage: '',
      successMessage: ''
    }
  },
  // computed: mapGetters(['allQuizz']),
  // created () {
  //   this.fetchQuizz()
  // }
  async created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    await axios.post(`/api/activity?${query}`)
      .then(r => {
        this.allActivities = r.data.activity
        this.allActivities.forEach(function (item) {
          item.active = false

          // format birthday
          item.participants.forEach(participant => {
            let age = moment(participant.birthday, "YYYY/MM/DD").fromNow(true)
            participant.birthday = age
          })
        })
      })
      .catch((e) => {
        localStorage.removeItem('token')
        this.$router.push('/login')
      })
  },
  methods: {
    ...mapActions(['deleteActivity']),
    formatDateonly (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    formatDateTime (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY à HH:MM:SS')
      }
    },
    toggleActivityStatus (_id) {
      this.activityStatus = !this.activityStatus
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      axios.get(`/api/activity/activityStatus/${_id}?${query}`)
        .then(response => {
          this.activityStatus = response.data
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    toggleInformationStatus (_id) {
      this.informationStatus = !this.informationStatus
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      axios.get(`/api/activity/informationStatus/${_id}?${query}`)
        .then(response => {
          this.informationStatus = response.data
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    toggleSuggestionStatus (_id) {
      this.suggestionStatus = !this.suggestionStatus
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      axios.get(`/api/activity/suggestionStatus/${_id}?${query}`)
        .then(response => {
          this.suggestionStatus = response.data
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    closeShowActivityParticipants(){
      this.showParticipants = ''
    },
    showActivityParticipants (activityID) {
      if (this.showParticipants == activityID) {
        this.showParticipants = ''
      } else {
        this.showParticipants = activityID
      }
    },
    selectAll () {
      const checkboxAll = document.getElementById("all")
      const checkboxs = document.getElementsByName("checkbox")

      checkboxs.forEach(checkbox => {
        if (checkboxAll.checked) {
          checkbox.checked = true
        } else {
          checkbox.checked = false
        }
      });
    },
    contactUser(numberOfParticipants) {
      event.preventDefault()
      this.userEmail = []

      if (this.isContact != true) {
        const checkboxs = document.getElementsByName("checkbox")

        checkboxs.forEach(checkbox => {
          if (checkbox.checked) {this.userEmail.push(checkbox.value)}
        })

        this.sendingTo = this.userEmail.length == numberOfParticipants ? "all" : (this.userEmail.length > 2 ? `${this.userEmail[0]}, ${this.userEmail[1]} et ${this.userEmail.length - 2} ${this.userEmail.length == 3 ? "autre" : "autres"}.` : (this.userEmail.toString().replace(",", " et ")))
        this.isContact = true
      } else {
        this.isContact = false
      }
    },
    async sendEmail () {
      event.preventDefault()
      let emailObject = {
        message: this.message,
        subject: this.emailSubject,
        receiver: this.userEmail
      }
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.post(`/api/admin/sendMail?${query}`, emailObject)
        .then(r => {
          if (r.status === 200) {
            this.isContact = false
            this.message = ''
            this.emailSubject = ''
            this.successMessage = this.userEmail.length > 1 ? "Messages envoyés!" : "Message envoyé!"
          }
        })
        .catch((e) => {
          this.errorMessage = "Oups! Il y a eu une erreur l'envoi du mail: " + e
        })
    }
  }
}
</script>
<style>
.new{
    position: absolute;
    width: 50px;
    margin: -23px;
}
/* Toggle */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>
