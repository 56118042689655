<template>
  <div class="ajsdnksa">
    THIS IS HOME PAGE
  </div>
</template>
<script>
  import { VueAgile } from '@/assets/vue-agile'
  import axios from 'axios'
  import moment from "moment";

  export default {
    created () {
      this.$router.push({ name: 'Information' })
    }
  }
</script>

