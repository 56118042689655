import { createRouter, createWebHistory } from 'vue-router'


// ADMIN SIDE
import Admin from '@/views/Admin/Admin'
import AdminPanelActivities from '@/views/Admin/AdminPanel_Activity/Activities'
import AdminPanelAddActivity from '@/views/Admin/AdminPanel_Activity/AddActivity'
import AdminPanelEditActivity from '@/views/Admin/AdminPanel_Activity/EditActivity'
import AdminPanelQuizz from '@/views/Admin/AdminPanel_Quiz/Quizz'
import AdminPanelAddQuiz from '@/views/Admin/AdminPanel_Quiz/AddQuiz'
import AdminPanelEditQuiz from '@/views/Admin/AdminPanel_Quiz/EditQuiz'
import AdminPanelUsers from '@/views/Admin/AdminPanel_User/Users'
import AdminPanelBlacklisted from '@/views/Admin/AdminPanel_Blacklisted/Blacklisted'
import AdminPanelShowActivity from '@/views/Admin/AdminPanel_Activity/ShowActivity'
import axios from 'axios'

// LAYOUT
import LayoutIsAdmin from '../layouts/LayoutIsAdmin'
import IndexPage from '@/views/front-end/Index'

const routes = [
  // GENERAL
  {
    name: 'Home',
    path: '/',
    component: IndexPage,
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }
  },
  // ADMIN SIDE
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/activities',
    name: 'AdminPanelActivities',
    component: AdminPanelActivities,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/activities/:id/show',
    name: 'AdminPanelShowActivity',
    component: AdminPanelShowActivity,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
  },
  {
    path: '/admin/activities/add',
    name: 'AdminPanelAddActivity',
    component: AdminPanelAddActivity,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/activities/:id/edit',
    name: 'AdminPanelEditActivity',
    component: AdminPanelEditActivity,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/quizz',
    name: 'AdminPanelQuizz',
    component: AdminPanelQuizz,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/quiz/add',
    name: 'AdminPanelAddQuiz',
    component: AdminPanelAddQuiz,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/quiz/:id/edit',
    name: 'AdminPanelEditQuiz',
    component: AdminPanelEditQuiz,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/users',
    name: 'AdminPanelUsers',
    component: AdminPanelUsers,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  {
    path: '/admin/blacklisted',
    name: 'AdminPanelBlacklisted',
    component: AdminPanelBlacklisted,
    beforeEnter: (to, from, next) => {
      checkAdmin(to, from, next)
    },
    meta: { layout: LayoutIsAdmin }
  },
  // OTHER
  {
    path: "/:catchAll(.*)",
    name: '404',
    component: () => import(/* webpackChunkName: "auth" */ '../views/front-end/404')
  },
  {
    path: '/registration',
    component: () => import(/* webpackChunkName: "auth" */ '../views/front-end/auth/registration-page')
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/front-end/auth/login-page')
  },
  {
    path: '/forgot-password',
    component: () => import(/* webpackChunkName: "auth" */ '../views/front-end/auth/forgot-password')
  },
  {
    path: '/reset-password',
    component: () => import(/* webpackChunkName: "auth" */ '../views/front-end/auth/reset-password')
  },
  {
    path: '/user-profile',
    component: () => import(/* webpackChunkName: "user" */ '../views/front-end/user/user-profile'),
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }
  },
  {
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "main" */ '../views/front-end/privacy-policy'),
  },
  {
    path: '/suggestion',
    component: () => import(/* webpackChunkName: "main" */ '../views/front-end/suggestion/suggestion'),
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import(/* webpackChunkName: "information" */ '../views/front-end/information/information'),
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }

  },
  {
    path: '/activities',
    component: () => import(/* webpackChunkName: "main" */ '../views/front-end/activities/activities'),
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }
  },
  {
    path: '/quiz',
    component: () => import(/* webpackChunkName: "main" */ '../views/front-end/quiz/quiz'),
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }
  },
  {
    path: '/map',
    component: () => import(/* webpackChunkName: "main" */ '../views/front-end/map/map'),
    beforeEnter: (to, from, next) => {
      checkUser(to, from, next)
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const checkUser = function (to, from, next) {
  const token = JSON.parse(localStorage.getItem('token'))
  if (token) {
    next()
  } else {
    window.location.href = '/login'
  }
}

const checkAdmin = function (to, from, next) {
  const token = JSON.parse(localStorage.getItem('token'))
  if (token) {
    let query = {secret_token:token}
    axios.get('/api/user/myProfil', {params:query})
      .then(r => {
          if (r.data.admin) {
            next()
          } else {
            alert("La page à laquelle vous essayez d'acceder est reservée aux administrateurs")
            window.location.href = '/information'
          }
      })
  }else {
    window.location.href = '/login'
  }
}

export default router
