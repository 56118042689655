
<template>
  <div class="bg-gray-100">

      <HeaderBlock/>

  <div class="default-layout">
    <div id="root-wrapper">
      <div class="h-screen flex overflow-hidden bg-gray-100">
        <TransitionRoot as="template" :show="sidebarOpen">
          <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-700">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex-shrink-0 flex items-center px-4">
<!--                  <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg" alt="Workflow" />-->
                </div>
                <div class="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav class="px-2 space-y-1">
                    <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-600 text-white' : 'text-indigo-100 hover:bg-gray-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                      <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-red-300" aria-hidden="true" />
                      {{ item.name }}
                    </a>
                  </nav>
                </div>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 w-14" aria-hidden="true">
              <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
          </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden bg-gray-800 md:flex md:flex-shrink-0">
          <div class="flex flex-col w-64">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
              <div class="mt-5 flex-1 flex flex-col">
                <nav class="flex-1 px-2 space-y-1">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current , 'text-red-100 hover:bg-red-600', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                    <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6 text-red-300" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
          <main class="flex-1 relative overflow-y-auto focus:outline-none">
            <div class="py-6">
              <slot />
            </div>
          </main>
        </div>
      </div>
    </div>
    <FooterBlock />
  </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {
  BellIcon,
  // CalendarIcon,
  // ChartBarIcon,
  FolderIcon,
  HomeIcon,
  // InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  BanIcon
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'
import HeaderBlock from '@/components/main/header'
import FooterBlock from "@/components/main/footer";

const navigation = [
  { name: 'Activités', href: '/admin/activities', icon: HomeIcon, current: true },
  { name: 'Quizz', href: '/admin/quizz', icon: FolderIcon, current: false },
  { name: 'Utilisateurs', href: '/admin/users', icon: UsersIcon, current: false },
  { name: 'Blacklist', href: '/admin/blacklisted', icon: BanIcon, current: false }
]

export default {
  components: {
    HeaderBlock,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    FooterBlock,

  },
  setup () {
    const sidebarOpen = ref(false)
    const activities = [{
      type: 'Jeu',
      title: 'Loup-garou geant',
      adress: 'gymnase',
      starting_date: '01/07/2021',
      ending_date: '01/07/2021',
      starting_time: '10:00',
      ending_time: '12:00',
      created_at: '30/06/2021',
      participants: '0',
      status: 'finished',
      vote: '40',
      published: 'true/false'
    }
      // More people...
    ]
    return {
      navigation,
      sidebarOpen,
      activities
    }
  },
  data () {
    return {
      isShowPopupFaq: false,
      isShowPopupMobile: false,
      isShowPopup: false,
      isMobileMenu: false,
      currentHelperText: {},
      isLogin: true,
      userProfile: {
        admin: null,
        _id:'',
        pseudo: '',
        confirmation: '',
        email: '',
        birthday: '',
        password: '',
        sex: '',
        postal_code: '',
        avatar: {},
        avatarURL: '',
        interests: []
      },
    }
  },
}

</script>
<style scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

#root-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0 0 0;
}
</style>
