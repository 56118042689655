<template>
  <div class="footer pb-6">
    <p>© 2021 Fernelmove tous droits réservés</p>
  </div>
</template>

<script>
export default {
  name: 'FooterBlock'
}
</script>

<style scoped>
@media screen and (max-width: 480px) {
  .footer {
    padding-bottom: .5rem;
  }
}
</style>
