<template xmlns="http://www.w3.org/1999/html">
  <nav class="nav-bar relative">

    <div :class="['mobile-menu', {active: isMobileMenu}]">

      <div v-show="isShowPopupFaq" class="nav-bar__popup-mobile shadow-xl flex flex-col items-center p-8">

        <div @click="closePrivacy" class="center-mobile z-10 active">
          <div></div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="#030104" xmlns:v="https://vecta.io/nano"><path d="M17.6 30a2 2 0 1 1-4 0c0-1.109.895-2 2-2s2 .891 2 2zm-1.924-4.023c-1.336 0-2.59-.523-3.535-1.469-.945-1.105-1.465-2.359-1.465-3.695s.52-2.59 1.465-3.371l6.688-6.688C19.584 9.996 20 8.992 20 7.926s-.416-2.074-1.172-2.828a4 4 0 0 0-5.654 0C12.416 5.852 12 6.855 12 7.926H8a7.95 7.95 0 0 1 2.348-5.66c3.02-3.023 8.285-3.02 11.309.004A7.93 7.93 0 0 1 24 7.926a7.94 7.94 0 0 1-2.344 5.656l-6.688 6.523c-.389.391-.389 1.023 0 1.414a1 1 0 0 0 1.414 0 1.01 1.01 0 0 0 .293-.711h4c0 1.336-.52 2.594-1.465 3.699a4.96 4.96 0 0 1-3.534 1.47h0z"/></svg>
        <h4 class="c-title">Comment ça marche?</h4>
        <div class="mb-5">
          <p>
          Cette application est destinée aux jeunes entre 13 et 20 ans de la commune de Fernelmont pour suggérer, participer à des activités, jouer et connaître mieux sa commune.
          </p>
          <br>
          <p>
            <b>Accueil:</b> Tu retrouvera les informations de ta commune.<br/>
            <b>Activité:</b> Tu peux indiquer que tu participeras à l’activité proposée.<br/>
            <b>Suggestion:</b> Tu peux voter ou suggérer une activité.<br/>
            <b>Quizz:</b> Trouve les bonnes réponses et accumule des points.<br/>
            <b>Plan:</b> Tu peux retrouver tes activités et lieux d'échange mis à ta disposition.<br/>
            <b>Compte:</b> Tu peux organiser et voir ton profil.</p>
        </div>
        <router-link @click="closePrivacy" to="/privacy-policy" class="inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900 mb-2">politique de confidentialité</router-link>
      </div>

      <div class="mobile-menu__bg">
        <div class="nav-bar__center-mobile flex flex-col align-self-center">
          <router-link @click="showMobMenu" class="nav-item-mobile" to="/information">Informations</router-link>
          <router-link @click="showMobMenu" class="nav-item-mobile" to="/activities">Activités</router-link>
          <router-link @click="showMobMenu" class="nav-item-mobile" to="/suggestion">Suggestions</router-link>
          <router-link @click="showMobMenu" class="nav-item-mobile" to="/quiz">Quizz</router-link>
          <router-link @click="showMobMenu" class="nav-item-mobile" to="/map">Plan</router-link>
          <router-link v-if="isAdmin || userProfile.admin" @click="showMobMenu" class="nav-item-mobile" to="/admin">Admin</router-link>
          <div class="nav-item-mobile btn-login" @click="auth">
            <template v-if="isLogin">
              Déconnexion
            </template>
            <router-link @click="showMobMenu" class="nav-item-mobile" to="/login" v-else>Connexion</router-link>
          </div>
          <a v-if="isInstall" @click="installMe" id="installButton" class="nav-item-mobile btn-install">
            Installe moi !
          </a>

          <router-link @click="showMobMenu" class="user-icon flex user-profile self-center" to="/user-profile">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:v="https://vecta.io/nano"><path d="M256 0c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135S330.439 0 256 0zm167.966 358.195C387.006 320.667 338.009 300 286 300h-60c-52.008 0-101.006 20.667-137.966 58.195C51.255 395.539 31 444.833 31 497c0 8.284 6.716 15 15 15h420c8.284 0 15-6.716 15-15 0-52.167-20.255-101.461-57.034-138.805z"/></svg>
            <p>Compte</p>
          </router-link>
          <a class="user-tutorial-mobile relative self-center 123" href="#">
            <svg @click="showPopup('faq')" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="#030104" xmlns:v="https://vecta.io/nano"><path d="M17.6 30a2 2 0 1 1-4 0c0-1.109.895-2 2-2s2 .891 2 2zm-1.924-4.023c-1.336 0-2.59-.523-3.535-1.469-.945-1.105-1.465-2.359-1.465-3.695s.52-2.59 1.465-3.371l6.688-6.688C19.584 9.996 20 8.992 20 7.926s-.416-2.074-1.172-2.828a4 4 0 0 0-5.654 0C12.416 5.852 12 6.855 12 7.926H8a7.95 7.95 0 0 1 2.348-5.66c3.02-3.023 8.285-3.02 11.309.004A7.93 7.93 0 0 1 24 7.926a7.94 7.94 0 0 1-2.344 5.656l-6.688 6.523c-.389.391-.389 1.023 0 1.414a1 1 0 0 0 1.414 0 1.01 1.01 0 0 0 .293-.711h4c0 1.336-.52 2.594-1.465 3.699a4.96 4.96 0 0 1-3.534 1.47h0z"/></svg>
          </a>
        </div>
      </div>
    </div>

    <div class="nav-bar__container c-container flex justify-between relative z-40 bg-white bg-opacity-20 pb-1 ">
      <div class="nav-bar__left">
        <router-link to="/">
          <img class="header-link mt-3" alt="logo" src="/img/FernelLogoFinalFinal/FernelLogoFinal.svg">
        </router-link>
      </div>

      <div @click="showMobMenu" :class="['center relative z-10', {active: isMobileMenu}]">
        <div></div>
      </div>

      <div class="nav-bar__center flex items-center justify-between mt-3">
        <router-link class="nav-item" to="/information">Informations</router-link>
        <router-link class="nav-item" to="/activities">Activités</router-link>
        <router-link class="nav-item" to="/suggestion">Suggestions</router-link>
        <router-link class="nav-item" to="/quiz">Quizz</router-link>
        <router-link class="nav-item" to="/map">Plan</router-link>
        <router-link v-if="isAdmin || userProfile.admin" class="nav-item" to="/admin">Admin</router-link>
        <div class="nav-item btn-login" @click="auth">
          <p v-if="isLogin">Déconnexion</p>
          <router-link class="nav-item" to="/login" v-else>Connexion</router-link>
        </div>
      </div>

      <div class="nav-bar__right flex">
        <a v-if="isInstall" @click="installMe" id="installButton" class="nav-item btn-install">
          Installe moi !
        </a>

        <a class="user-tutorial relative mt-3" href="#">
          <svg @click="showPopup('desc')" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="#030104" xmlns:v="https://vecta.io/nano"><path d="M17.6 30a2 2 0 1 1-4 0c0-1.109.895-2 2-2s2 .891 2 2zm-1.924-4.023c-1.336 0-2.59-.523-3.535-1.469-.945-1.105-1.465-2.359-1.465-3.695s.52-2.59 1.465-3.371l6.688-6.688C19.584 9.996 20 8.992 20 7.926s-.416-2.074-1.172-2.828a4 4 0 0 0-5.654 0C12.416 5.852 12 6.855 12 7.926H8a7.95 7.95 0 0 1 2.348-5.66c3.02-3.023 8.285-3.02 11.309.004A7.93 7.93 0 0 1 24 7.926a7.94 7.94 0 0 1-2.344 5.656l-6.688 6.523c-.389.391-.389 1.023 0 1.414a1 1 0 0 0 1.414 0 1.01 1.01 0 0 0 .293-.711h4c0 1.336-.52 2.594-1.465 3.699a4.96 4.96 0 0 1-3.534 1.47h0z"/></svg>
          <div v-show="isShowPopup" class="nav-bar__popup shadow-xl flex flex-col items-center p-8 z-10">

            <div id="mdiv" class="cursor-pointer" @click="showPopup('desc')">
              <div class="mdiv-l">
                <div class="md-r"></div>
              </div>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="#030104" xmlns:v="https://vecta.io/nano"><path d="M17.6 30a2 2 0 1 1-4 0c0-1.109.895-2 2-2s2 .891 2 2zm-1.924-4.023c-1.336 0-2.59-.523-3.535-1.469-.945-1.105-1.465-2.359-1.465-3.695s.52-2.59 1.465-3.371l6.688-6.688C19.584 9.996 20 8.992 20 7.926s-.416-2.074-1.172-2.828a4 4 0 0 0-5.654 0C12.416 5.852 12 6.855 12 7.926H8a7.95 7.95 0 0 1 2.348-5.66c3.02-3.023 8.285-3.02 11.309.004A7.93 7.93 0 0 1 24 7.926a7.94 7.94 0 0 1-2.344 5.656l-6.688 6.523c-.389.391-.389 1.023 0 1.414a1 1 0 0 0 1.414 0 1.01 1.01 0 0 0 .293-.711h4c0 1.336-.52 2.594-1.465 3.699a4.96 4.96 0 0 1-3.534 1.47h0z"/></svg>
            <h4 class="c-title">Comment ça marche?</h4>
            <p v-if="this.currentHelperText[0]" class="mb-5">
              {{ currentHelperText[0].text ? currentHelperText[0].text : 'just some text' }} </p>
            <router-link to="/privacy-policy" class="inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900 mb-2">politique de confidentialité</router-link>
          </div>
        </a>

        <router-link class="user-icon flex user-profile mt-3" to="/user-profile">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:v="https://vecta.io/nano"><path d="M256 0c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135S330.439 0 256 0zm167.966 358.195C387.006 320.667 338.009 300 286 300h-60c-52.008 0-101.006 20.667-137.966 58.195C51.255 395.539 31 444.833 31 497c0 8.284 6.716 15 15 15h420c8.284 0 15-6.716 15-15 0-52.167-20.255-101.461-57.034-138.805z"/></svg>
          <p>Compte</p>
        </router-link>
      </div>

    </div>
  </nav>
</template>

<script>
import { helperText } from '@/assets/data/main/faq-text.json'
import axios from "axios";

export default {
  name: 'HeaderBlock',
  props: ['check'],
  emits: ["logout"],
  data () {
    return {
      isAdmin: false,
      isShowPopupFaq: false,
      isShowPopupMobile: false,
      isShowPopup: false,
      isMobileMenu: false,
      currentHelperText: {},
      isLogin: JSON.parse(localStorage.getItem('token')) ?  true : false,
      isInstall: false,
      deferredPrompt: '',
      userProfile: {
        admin: null,
        _id:'',
        pseudo: '',
        confirmation: '',
        email: '',
        birthday: '',
        password: '',
        sex: '',
        postal_code: '',
        avatar: {},
        avatarURL: '',
        interests: []
      },
    }
  },
  watch: {
    $route () {
      this.updateHelperText()
      this.checkIsLogin()
    },
    check () {
      this.checkAdmin()
    }
  },
  async created () {
    if(localStorage.getItem('token')){
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      const user = await axios.get(`/api/user/myProfil?${query}`)
        .then(r => {
          const { admin, email, pseudo, interest: interests, birthday, avatarUrl, avatar, points, _id } = r.data

          this.userProfile = {
            admin,
            _id,
            pseudo,
            email,
            interests,
            birthday: birthday.substr(0,10),
            avatarUrl,
            avatar,
            points
          }
        })
    }
    // Initialize deferredPrompt for use later to show browser install prompt.
    window.addEventListener('beforeinstallprompt', (evt) => {
        // Stash the event so it can be triggered later.
        this.deferredPrompt = evt;

        // creating install button
        console.log("beforeinstallprompt detected");
        this.isInstall= true;
    });
    // setTimeout(() => {
    //   if(localStorage.getItem('installable')){
    //     this.deferredPrompt = localStorage.getItem('installable')
    //     this.isInstall = true
    //   }
    // },100)
  },
  methods: {
    checkIsLogin () {
      const token = JSON.parse(localStorage.getItem('token'))
      token ? this.isLogin = true : this.isLogin = false
    },
    showPopup (type) {
      if (type === 'mobile') this.isShowPopupMobile = !this.isShowPopupMobile
      if (type === 'desc') this.isShowPopup = !this.isShowPopup
      if (type === 'faq') this.isShowPopupFaq = !this.isShowPopupFaq
    },
    showMobMenu () {
      this.isMobileMenu = !this.isMobileMenu

      this.isMobileMenu
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')
    },
    closePrivacy () {
      this.isShowPopupFaq = !this.isShowPopupFaq
      this.isMobileMenu = !this.isMobileMenu

      this.isMobileMenu
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')
    },
    updateHelperText () {
      this.currentHelperText = { ...helperText.filter(item => item.path === this.$route.path) }
    },
    auth () {
      const token = JSON.parse(localStorage.getItem('token'))
      if (token) {
        localStorage.clear()
        this.$emit('logout', true)
        this.isAdmin = false
        this.isLogin = false
        this.$router.push('/login')
      }
    },
    async checkAdmin () {
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      const user = await axios.get(`/api/user/myProfil?${query}`)
        .then(r => {
          if (r.data.admin) {
            this.isAdmin = true
          } else {
            this.isAdmin = false
          }
        })
    },
    async installMe () {
      // Show the install prompt
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;

      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);

      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
      this.isInstall= false;
    }
  }
}
</script>

<style>
#installButton {
  cursor: pointer;
}

.nav-bar .btn-install {
  background-color: #46505e;
  color: #fff;
  border-radius: 6px;
  margin: 10px 0px 0px 20px;
}

.nav-bar .nav-bar__center-mobile .btn-install {
  margin-bottom: 5px;
}

.nav-item-mobile,
.nav-item {
  padding: 0.5rem
}

.header-link {
  max-width: 80px;
  width: 100%;
  margin: 0;
  position: absolute;
  top: -24px;
  left: 21px;
}

.c-container{
  padding-bottom: 1rem;
}
.nav-bar__center {
  max-width: 400px;
  width: 100%;
}

.nav-item-mobile.router-link-active,
.nav-item.router-link-active {
  background-color: #46505e;
  color: #fff;
  border-radius: 6px;
}

.nav-bar__right.flex {
  align-items: center;
}
.nav-bar__left{
  margin-bottom: 4rem;
}

.nav-bar__popup-mobile > svg,
.user-tutorial-mobile > svg,
.nav-bar__popup > svg,
.user-tutorial > svg {
  max-width: 24px;
  width: 100%;
  height: 24px;
  margin-right: 10px;
}

.nav-bar__popup-mobile > svg {
  margin-right: 0;
  margin-bottom: 20px;
  max-width: 40px;
  height: 40px;
}

.nav-bar__popup-mobile > a {
  font-size: 20px;
}

.nav-bar__popup-mobile > p {
  max-width: 400px;
}

.user-tutorial-mobile > svg {
  margin-right: 0;
  max-width: 40px;
  height: 40px;
  margin-top: 20px;
}

.nav-bar__popup-mobile,
.nav-bar__popup {
  position: absolute;
  right: 0;
  width: 300px;
  background-color: #fff;
}

.nav-bar__popup-mobile {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 120;
  width: initial;
  height: 100vh;
  justify-content: center;
}

.btn-login {
  margin-top: 3px;
  white-space: nowrap;
  cursor: pointer;
}

.user-icon {
  border: 1px solid #46505e;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  color: #46505e;
  transition: all 0.4s ease-out;
}

.user-icon:hover {
  background-color: #8899B2;
  color: #fff;
  border-color: #fff;
}

.user-icon > svg {
  max-width: 24px;
  width: 100%;
  height: 24px;
}

#mdiv2,
#mdiv {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  border-radius: 6px;
  transition: all 0.4s ease-out;
}

#mdiv2 {
  top: 20px;
  width: initial;
  height: initial;
}

#mdiv:hover {
  background-color: #fff;
}

#mdiv:hover .mdiv-l,
#mdiv:hover .md-r {
  background-color: #46505e;
}

.mdiv-l,
.md-r {
  transition: all 0.4s ease-out;
}

.mdiv-l {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: #46505e;
  transform: rotate(45deg);
  Z-index: 1;
}

.md-r {
  height: 25px;
  width: 2px;
  background-color: #46505e;
  transform: rotate(90deg);
  Z-index: 2;
}

.center-mobile,
.center {
  right: 5%;
  position: absolute;
  width: 50px;
  cursor: pointer;
  display: none;
}

.center-mobile {
  top: 3%;
  right: 49px;
  display: block;
}

.center-mobile:before,
.center-mobile:after,
.center-mobile div,
.center:before,
.center:after,
.center div {
  background: #53616f;
  content: "";
  display: block;
  height: 6px;
  margin: 7px 0;
  transition: 0.5s;
}

.center-mobile.active:before,
.center.active:before {
  transform: translateY(12px) rotate(135deg);
}
.center-mobile.active:after,
.center.active:after {
  transform: translateY(-12px) rotate(-135deg);
}
.center-mobile.active div,
.center.active div {
  transform: scale(0);
}

.mobile-menu {
  transition: all 0.8s ease-out;
}

.mobile-menu__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  opacity: 0;
  z-index: -10;
  transition: all 0.8s ease-out;
}

.mobile-menu.active > .mobile-menu__bg {
  background-color: #fff;
  opacity: 1;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu.active > body {
  position:fixed;
  overflow:hidden;
}

.nav-item-mobile {
  color: #46505e;
  font-size: 24px;
  font-weight: 700;
}

.router-link-active.user-profile {
  background-color: #8899B2;
  color: #fff;
  border-color: #fff;
}

@media screen and (max-width: 991px) {
  .header-link {
    max-width: 64px;
    width: 100%;
    margin: 0;
    position: absolute;
    top: -15px;
    left: -7px;
  }
  .nav-item {
    padding: 0.5rem 8px;
    font-size: 15px;
  }

  .user-icon {
    padding: 0.3rem;
    font-size: 15px;
    align-items: center;
  }

  .user-icon > svg {
    max-width: 18px;
    height: 18px;
  }

  .nav-bar__center {
    max-width: initial;
    width: initial;
  }
}

@media screen and (max-width: 767px) {
  .center {
    display: block;
  }

  .nav-bar__center {
    display: none!important;
  }

  .nav-bar__right {
    display: none!important;
  }
}
</style>
