<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 class="text-2xl font-semibold text-gray-900">Emails bloqués</h1>
    </div>
    <div class="max-w-7xl mx-auto pl-1 sm:pl-1 md:pl-1">
        <div class="py-4">
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-max sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-max divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Email
                                        </th>
                                        <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200" >
                                    <tr v-for="email in emails">
                                        <td class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ email.email }}
                                        </td>
                                        <td class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <a class="cursor-pointer" @click="unblockUser(email.email)">✔️</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

axios.defaults.crossDomain = true

export default {
  data () {
    return {
      emails: [],
      errors: []
    }
  },

  // Fetches blocekd email when the page is created.
  created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    axios.get(`/api/admin/blacklisted?${query}`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.emails = response.data.emails
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    async unblockUser (email) {
        if(confirm(`Etes vous sûr de vouloir débloquer ${email}?`) == true){
            const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
            await axios.delete(`/api/user/unblock/${email}?${query}`)

            location.reload()
        }
    }
  }
}
</script>
