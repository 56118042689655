<template>
  <div v-show="!isContact">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Utilisateurs</h1>
      <p v-if="successMessage" style="color: green">{{successMessage}}</p>
      <p v-if="errorMessage" style="color: red">{{errorMessage}}</p>
    </div>
    <div class="max-w-7xl mx-auto pl-1 sm:pl-1 md:pl-1">
      <div class="py-4">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Pseudo
                    </th>
                    <th scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Village
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sexe
                    </th>
                    <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date de naissance
                    </th>
                    <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Interêts
                    </th>
                    <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      🤬
                    </th>
                    <th scope="col" class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Admin
                    </th>
                    <th scope="col" class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Points
                    </th>
                    <th scope="col" class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200" >
                  <tr v-for="user in users" :key="user.id">

                    <td class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ user.pseudo }}
                    </td>
                    <td class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ user.email }}
                    </td>
                    <td class="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ user.village }}
                    </td>
                    <td class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ user.sexe }}
                    </td>
                    <td class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ formatDateonly (user.birthday) }}
                    </td>
                    <td class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider dropdown">
                    {{ user.interest.length }}
                      <div v-if="user.interest.length > 0" class="dropdown-content">
                        <p v-for="interest in user.interest">{{interest}}</p>
                      </div>
                    </td>
                    <td class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider dropdown">
                      {{ user.insult.length }}
                      <div v-if="user.insult.length > 0" class="dropdown-content">
                        <a v-for="insult in user.insult">{{insult.title}}</a>
                      </div>
                    </td>
                    <td class="px-5 py-3  whitespace-nowrap text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <label :for="'toogleAdmin'+user._id" class="flex items-center cursor-pointer">
                        <!-- toggle -->
                        <div class="relative">
                          <!-- input -->
                          <input v-if="user.admin == true" @click='toggleAdmin(user._id)' :id="'toogleAdmin'+user._id" type="checkbox" class="sr-only content-center" checked />
                          <input v-else @click='toggleAdmin(user._id)' :id="'toogleAdmin'+user._id" type="checkbox" class="sr-only content-center" />
                          <!-- line -->
                          <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                          <!-- dot -->
                          <div  class="dot absolute w-6 h-6 bg-red-600 rounded-full shadow -left-1 -top-1 transition"></div>
                        </div>
                      </label>
                    </td>
                    <td class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider dropdown">
                      {{ user.points }}
                    </td>
                    <td >
                    <a class="cursor-pointer" @click="contactUser(user.email)">✉️</a>
                    |
                    <a class="cursor-pointer" @click="blockUser({id:user._id, pseudo:user.pseudo})">⛔</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isContact">
    <form v-on:submit="sendEmail">

      <p for="message" class="block text-sm font-medium text-gray-700"><b>Sending message to: {{userEmail}}</b></p>
      <div class="mt-1">
        <label for="subject" class="block text-sm font-medium text-gray-700">Sujet:</label>
        <input type="text" v-model="emailSubject" required class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
      </div>
      <div class="mt-1">
        <label for="message" class="block text-sm font-medium text-gray-700">Message:</label>
        <textarea v-model="message" required id="message" name="message" rows="4" class="py-3 px-4 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" style="width: 75%; margin: auto"/>
      </div>

      <button type="submit" class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
        Envoyer
      </button>
      <button type="button" @click="contactUser" class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
        Annuler
      </button>
    </form>
  </div>
</template>

<script>
import {  mapActions } from 'vuex'
import axios from 'axios'
import moment from "moment";

axios.defaults.crossDomain = true

export default {
  //points
  data () {
    return {
      users: [],
      errors: [],
      user:[],
      isContact: false,
      userEmail: '',
      emailSubject: '',
      message: '',
      errorMessage: '',
      successMessage: ''
    }
  },

  // Fetches posts when the component is created.
  created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    axios.get(`/api/user?${query}`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.users = response.data.user
        this.checkPending()
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    ...mapActions([ 'blockUser']),
    formatDateonly (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    toggleAdmin (_id) {
      const checkBox = document.getElementById(`toogleAdmin${_id}`)
      const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`

      if (checkBox.classList.contains("pending")) {
        checkBox.classList.remove("pending")
        axios.put(`/api/admin/abortAdminRequest/${_id}?${query}`)
      } else {
        if (checkBox.checked) {
          checkBox.classList.add("pending")
        }
        axios.put(`/api/admin/${_id}?${query}`)
          .then(response => {
            this.toggleAdmin = response.data
          })
          .catch(function (error) {
            console.error(error)
          })
      }
    },
    checkPending () {
      const now =  Date.now()

      setTimeout(() => {
        this.users.forEach(user => {
          if (user.giveAdminExpires) {
            let expire = (new Date(user.giveAdminExpires).getTime());

            if (expire > now) {
              let checkBox = document.getElementById(`toogleAdmin${user._id}`)
              checkBox.classList.add("pending")
              checkBox.checked = true
            }
          }
        });
      }, 10);
    },
    contactUser(email) {
      event.preventDefault()
      this.userEmail = email

      if (this.isContact != true) {
        this.isContact = true
      } else {
        this.isContact = false
      }
    },
    async sendEmail () {
      event.preventDefault()
      let emailObject = {
        message: this.message,
        subject: this.emailSubject,
        receiver: this.userEmail
      }
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.post(`/api/admin/sendMailToOne?${query}`, emailObject)
        .then(r => {
          if (r.status === 200) {
            this.isContact = false
            this.message = ''
            this.emailSubject = ''
            this.successMessage = "Message envoyé!"
          }
        })
        .catch((e) => {
          this.errorMessage = "Oups! Il y a eu une erreur l'envoi du mail: " + e
        })
    }
  }
}
</script>
<style>
/* Toggle */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}


input.pending ~ .dot{
  transform: translateX(50%);
  background-color: #f0a800;
}

.dropdown {
  background-color: rgba(44, 62, 80, 0.2);
}

.acordion {
  background-color: rgba(44, 62, 80, 0.2);
  cursor: pointer;
}

.acordionBody {
  background-color: rgba(44, 62, 80, 0.05);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.scroll-enter-active {
  animation: animationFrames .2s;
}
.scroll-leave-active {
  animation: animationFrames .2s reverse;
}
@keyframes animationFrames{
  0% {
    transform:  translate(0px,-23px)  scaleY(0.10) ;
  }
  100% {
    transform:  translate(0px,0px)  scaleY(1.00) ;
  }
}

@-moz-keyframes animationFrames{
  0% {
    -moz-transform:  translate(0px,-23px)  scaleY(0.10) ;
  }
  100% {
    -moz-transform:  translate(0px,0px)  scaleY(1.00) ;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform:  translate(0px,-23px)  scaleY(0.10) ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  scaleY(1.00) ;
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform:  translate(0px,-23px)  scaleY(0.10) ;
  }
  100% {
    -o-transform:  translate(0px,0px)  scaleY(1.00) ;
  }
}
</style>
