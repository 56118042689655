<template>
    <div id="ModalContainer" class="background"  v-if="selectImage">
        <div id="container">
            <div class="modalHeader">
                <p>Choisis ta photo</p>
            </div>
            <div  class="cardContainer">
                <div v-for="picture in pictures" v-on:click="validateImage(picture.asset_id)" :style="'background-image: url('+picture.secure_url+');'" class="myCard m-1">
                    <input :id="picture.asset_id" class="radioInput" type="radio" name="url" v-model="url" :value="picture.secure_url">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            selectImage: false,
            visible: false,
            selected: false,
            pictures: [],
            url: ''
        }
    },
    async created () {
        console.log("cloudinary is here!")
        let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
        await axios.get(`/api/cloudinary?${query}`)
            .then(r => {
                this.pictures = r.data.resources
            })

        setTimeout(() => {
            window.addEventListener('click', (e) => {  
                if (this.visible) {
                    // if user click outside of the modal
                    if (this.selected || !document.getElementById("container").contains(e.target) && this.visible === true){
                        this.closeModal()
                    }
                }
                if (this.selectImage === true && this.visible === false) {this.visible = true}
            });

            document.getElementById("toggleModal").addEventListener('click', () => {
                this.selectImage = true
            })
        }, 10)
    },
    methods: {
        validateImage (id) {
            const target = document.getElementById(id)

            target.checked = true
            this.url = target.value
            this.selected = true

            // return value to the parent component
            this.$emit('url', this.url)
        },
        closeModal () {
            this.selectImage = false
            this.visible = false
            this.selected = false
        }
    }
}
</script>

<style scoped>
.background{
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
}
#container{
    width: 70%;
    height: 80%;
    margin: auto;
    margin-top: 60px;
    background-color: white;
    display: flex;
    flex-flow: column;
}
#container .modalHeader{
    flex: 0 1 auto;
    width: 100%;
    height:30px;
    background-color: #2c3e50;
    color: white;
    z-index: 100;   
}
.cardContainer{
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: inherit;
    overflow-y: scroll;
    padding-top: 5px;
}
.myCard{
    cursor: pointer;
    border: red solid 1px;
    background-size: cover;
    width: 200px;
    height: 260px;
    background-repeat: no-repeat;
    background-position: center;
}
.radioInput{
    position: relative;
    top: 5px;
    left: -80px;
}
</style>