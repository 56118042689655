<template>
<cloudinaryModal @url="urlSubmited"></cloudinaryModal>
  <div class="bg-grey py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="relative max-w-xl mx-auto">
      <svg class="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg class="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div class="text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Modifier Quizz
        </h2>
      </div>
      <div class="mt-12">
        <form @submit="onSubmit">
          <div>
            <label for="title" class="block text-sm font-medium text-gray-700">question:</label>
            <input v-model="quiz.question" type="text" name="title" id="title" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
          </div>
          <div class="sm:col-span-2">
            <label for="picture" class="block text-sm font-medium text-gray-700">Ajouter une photo</label>
            <div class="mt-1">
              <button id="open-cloudinary-btn">Oups... il y a un soucis avec Cloudinary</button>
              <img src="" id="pictureTag" class="picture">

              <!-- <button id="toggleModal" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Séléctionner image
              </button>
              <img :src="url" id="pictureTag" class="pt-2"> -->
            </div>
          </div>
          <div>
            <label for="answer1" class="block text-sm font-medium text-gray-700">Réponse 1</label>
            <div class="mt-1">
              <input v-model="quiz.answers.a" type="text" name="answer1" id="answer1" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div>
            <label for="answer2" class="block text-sm font-medium text-gray-700">Réponse 2</label>
            <div class="mt-1">
              <input v-model="quiz.answers.b" type="text" name="answer2" id="answer2" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div>
            <label for="answer3" class="block text-sm font-medium text-gray-700">Réponse 3</label>
            <div class="mt-1">
              <input v-model="quiz.answers.c" type="text" name="answer3" id="answer3" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div>
            <label for="correctAnswer" class="block text-sm font-medium text-gray-700">Bonne réponse:</label>
            <div class="mt-1">
              <select  v-model="quiz.correctAnswer" name="correctAnswer" id="correctAnswer" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                <option selected>Selectionnez la bonne réponse</option>
                <option value="a">{{quiz.answers.a}}</option>           
                <option value="b">{{quiz.answers.b}}</option>           
                <option value="c">{{quiz.answers.c}}</option>           
              </select>  
            </div>
          </div>
          <div class="sm:col-span-2">
            <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
              modifier
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { mapActions } from 'vuex'
import { onMounted } from "vue";
import cloudinaryModal from '@/components/cloudinary/cloudinaryModal'
axios.defaults.crossDomain = true

// import { Switch } from '@headlessui/vue'

export default {
  components: {
    cloudinaryModal
    // Switch
  },
  data () {
    onMounted(() => {
      window.ml = cloudinary.createMediaLibrary({
        cloud_name: process.env.VUE_APP_CLOUDNAME,
        api_key: process.env.VUE_APP_APIKEY,
        username: process.env.VUE_APP_USERNAME,
        remove_header: true,
        max_files: '1',
        insert_caption: 'insert',
        default_transformations: [
          [{width: 425, crop: "scale"}]
        ],
        button_class: 'inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        button_caption: 'Séléctionner image'
      }, {
        insertHandler: function (data) {
            data.assets.forEach(asset => { 
              document.getElementById("pictureTag").src = asset.derived[0].secure_url
              })
            }
        },
        document.getElementById("open-cloudinary-btn")
      )
    })

    return {
      quiz: {
        answers: {a:'',b:'',c:''},
      },
      url: ''
    }
  },
  setup () {
    const agreed = ref(false)

    return {
      agreed
    }
  },
  created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    axios.get(`/api/quiz/${this.$route.params.id}?${query}`)
      .then(response => {
        this.url = response.data.picture
        this.quiz = response.data
        this.answers = response.data.answers
      })
      .catch(function (error) {
        console.error(error)
      })
  },
  methods: {
    ...mapActions(['updateQuiz']),
    onSubmit (e) {
      e.preventDefault()
      const updatedQuiz = {
        id: this.quiz._id,
        question: this.quiz.question,
        picture: this.url,
        answers: this.quiz.answers,
        correctAnswer: this.quiz.correctAnswer
      }
      this.updateQuiz(updatedQuiz)
    },
    urlSubmited (url) {
      this.url = url
    }
  }
}
</script>
