<template>
<div className="row py-9 justify-content-center whitespace-nowrap font-medium text-gray-900">
  <h1> Welcome to your dashboard </h1>
</div>
</template>

<script>
  import { VueAgile } from '@/assets/vue-agile'
  import axios from 'axios'
  import moment from "moment";

  export default {
    created () {
      this.$router.push({ name: 'AdminPanelActivities' })
    }
  }
</script>
<style scoped>
</style>
