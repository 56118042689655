import axios from 'axios'
import router from '@/router'
const state = {
  quizz: [],
  quiz: {}
}

const getters = {
  allQuizz: state => state.quizz
}

const actions = {
  async fetchQuizz ({ commit }) {
    const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    const response = await axios.get(
      `/api/quiz?${query}`
    )
    commit('setQuizz', response.data)
  },
  async addQuiz ({ commit }, quiz) {
    const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    const response = await axios.post(
      `/api/quiz/add_quiz?${query}`, quiz
    )

    commit('newQuiz', response.data)
    router.push({ name: 'AdminPanelQuizz' })
  },
  async deleteQuiz ({ commit }, id) {
    if(confirm("Est-tu sûr de vouloir supprimer ce quiz?") == true){
      const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.delete(`/api/quiz/${id}?${query}`)

      commit('removeQuiz', id)
      location.reload()
    }
  },
  async filterQuizz ({ commit }, e) {
    // Get selected number
    const limit = parseInt(e.target.options[e.target.options.selectedIndex].innerText)
    const response = await axios.get(`/api/quiz?_limit=${limit}`)

    commit('setQuizz', response.data)
  },
  async updateQuiz ({ commit }, updQuiz) {
    if(confirm("Est-tu sûr de vouloir modifier ce quiz?") == true){
      const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      const response = await axios.put(`/api/quiz/${updQuiz.id}?${query}`, updQuiz)

      commit('updateQuiz', response.data)
      router.push({ name: 'AdminPanelQuizz' })
    }
  }
}

const mutations = {
  setQuizz: (state, quizz) => (state.quizz = quizz),
  newQuiz: (state, quiz) => state.quizz.unshift(quiz),
  removeQuiz: (state, id) =>
    (state.quizz = state.quizz.filter(quiz => quiz.id !== id)),
  updateQuiz: (state, updQuiz) => {
    const index = state.quizz.findIndex(quiz => quiz.id === updQuiz.id)
    if (index !== -1) {
      state.quizz.splice(index, 1, updQuiz)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
