import { createStore } from 'vuex'
import AdminActivityStore from './modules/AdminActivityStore'
import AdminQuizStore from '@/store/modules/AdminQuizStore'
import AdminUserStore from '@/store/modules/AdminUserStore'

const store = createStore({
  modules: {
    AdminActivityStore,
    AdminQuizStore,
    AdminUserStore
  }
})
export default store
