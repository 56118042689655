<template>
  <div>
    <component :is="layout" id="root-wrapper">
      <router-view/>
    </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/default-layout'

export default {
  components: {
    DefaultLayout
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default-layout')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#pictureTag {
  max-width: 25%; 
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px
}
</style>
