import axios from 'axios'
import router from '@/router'
const state = {
  users: [],
  user: {}
}

const getters = {
  allUsers: state => state.users
}

const actions = {
  async fetchUsers ({ commit }) {
    const response = await axios.get(
      '/api/user'
    )

    commit('setUsers', response.data)
  },
  // ADD
  // async addUser ({ commit }, user) {
  //   const response = await axios.post(
  //     '/api/user/add_user', user
  //   )
  //
  //   commit('newUser', response.data)
  //   router.push({ name: 'AdminPanelActivities' })
  // },
  async blockUser ({ commit }, user) {
    if(confirm(`Etes vous sûr de vouloir bloquer ${user.pseudo}?`) == true){
      const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.delete(`/api/user/block/${user.id}?${query}`)
  
      commit('removeUser', user.id)
      // router.push({ name: 'AdminPanelActivities' })
      location.reload()
    }
  },
  async filterUsers ({ commit }, e) {
    // Get selected number
    const limit = parseInt(e.target.options[e.target.options.selectedIndex].innerText)
    const response = await axios.get(`/api/user?_limit=${limit}`)

    commit('setUsers', response.data)
  }
  // UPDATE
  // async updateUser ({ commit }, updUser) {
  //   const response = await axios.put(
  //     `/api/user/${updUser.id}`, updUser)
  //
  //   commit('updateUser', response.data)
  //   router.push({ name: 'AdminPanelActivities' })
  // }
}

const mutations = {
  setUsers: (state, users) => (state.users = users),
  // newUser: (state, user) => state.users.user.unshift(user),
  removeUser: (state, id) =>
    (state.users = state.users.filter(user => user.id !== id))
  // updateUser: (state, updUser) => {
  //   const index = state.users.user.findIndex(user => user.id === updUser.id)
  //   if (index !== -1) {
  //     state.users.user.splice(index, 1, updUser)
  //   }
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
