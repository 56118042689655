<template>
  <cloudinaryModal @url="urlSubmited"></cloudinaryModal>
  <div class="bg-grey py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="relative max-w-xl mx-auto">
      <svg class="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg class="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div class="text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Modifier activité
        </h2>
        <button @click="this.$router.push('/admin/activities')" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          retour
        </button>
      </div>
      <div class="mt-12">
        <form  @submit.prevent="onDblClick(activity)"  class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div>
          <label for="type" class="block text-sm font-medium text-gray-700">type</label>
            <div class="mt-1">
              <select v-model="activity.type" id="type" name="type"  class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                <option selected>{{ activity.type }}</option>
                <option>Musique</option>
                <option>Jeux</option>
                <option>Сinéma</option>
                <option>Lecture</option>
                <option>Art</option>
                <option>Sport</option>
                <option>Technologie</option>
                <option>Mouvement de jeunesse</option>
                <option>Autre</option>

              </select>
            </div>
          </div>
          <div>
            <label for="title" class="block text-sm font-medium text-gray-700">titre </label>
            <input v-model="activity.title" type="text" name="title" id="title" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
          </div>
          <div class="sm:col-span-2">
            <label for="picture" class="block text-sm font-medium text-gray-700">photo</label>
            <div class="mt-1">
              <button id="open-cloudinary-btn">Oups... il y a un soucis avec Cloudinary</button>
              <img src="" id="pictureTag" class="picture">

              <!-- <button id="toggleModal" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Séléctionner image
              </button>
              <img :src="url" id="pictureTag" class="pt-2"> -->
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="starting_date" class="block text-sm font-medium text-gray-700">date de début</label>
            <div class="mt-1">
              <input v-model="activity.starting_date" type="date" name="starting_date" id="starting_date" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="ending_date" class="block text-sm font-medium text-gray-700">date de fin</label>
            <div class="mt-1">
              <input v-model="activity.ending_date" id="ending_date" name="ending_date" type="date" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="starting_time" class="block text-sm font-medium text-gray-700">à partir de</label>
            <div class="mt-1">
              <input v-model="activity.starting_time" id="starting_time" name="starting_time" type="time" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="ending_time" class="block text-sm font-medium text-gray-700">jusqu'à</label>
            <div class="mt-1">
              <input v-model="activity.ending_time" id="ending_time" name="ending_time" type="time" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="address" class="block text-sm font-medium text-gray-700">adresse</label>
            <p v-if="errorAddress" style="color: red">{{errorAddress}}</p>
            <div class="mt-1">
              <input v-model="activity.address" @change="addressHasChanged" maxlength="29"  id="address" name="address" type="text" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="description" class="block text-sm font-medium text-gray-700">description</label>
            <div class="mt-1">
              <textarea v-model="activity.description" id="description" name="description" rows="4" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">email</label>
            <div class="mt-1">
              <input v-model="activity.email" id="email" name="email" type="email" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="gsm" class="block text-sm font-medium text-gray-700">gsm</label>
            <div class="mt-1">
              <input v-model="activity.gsm" id="gsm" name="gsm" type="number" maxlength="10" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
              Modifer
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { mapActions } from 'vuex'
import cloudinaryModal from '@/components/cloudinary/cloudinaryModal'
import mapboxgl from "mapbox-gl";
axios.defaults.crossDomain = true
import { onMounted } from "vue"

// import { Switch } from '@headlessui/vue'

export default {
  components: {
    cloudinaryModal
    // Switch
  },
  data () {
    onMounted(() => {
      window.ml = cloudinary.createMediaLibrary({
        cloud_name: process.env.VUE_APP_CLOUDNAME,
        api_key: process.env.VUE_APP_APIKEY,
        username: process.env.VUE_APP_USERNAME,
        remove_header: true,
        max_files: '1',
        insert_caption: 'insert',
        default_transformations: [
          [{width: 425, crop: "scale"}]
        ],
        button_class: 'inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        button_caption: 'Séléctionner image'
      }, {
        insertHandler: function (data) {
            data.assets.forEach(asset => {
              document.getElementById("pictureTag").src = asset.derived[0].secure_url
              })
            }
        },
        document.getElementById("open-cloudinary-btn")
      )
    })

    return {
      activity: {},
      activities: [],
      url: '',
      addressChange: false,
      errorAddress: '',
      gsm: '',
      email:''
    }
  },
  setup () {
    const agreed = ref(false)

    return {
      agreed
    }
  },
  created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    axios.get(`/api/activity/${this.$route.params.id}?${query}`)
      .then(response => {
        this.url = response.data.picture
        this.activity = response.data
      })
      .catch(function (error) {
        console.error(error)
      })
  },
  methods: {
    ...mapActions(['updateActivity']),
    async onDblClick () {
      if (this.addressChange) {
        // Get the coordinates for the given address
        await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.activity.address}%20Fernelmont%20Namur%20Belgium.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`)
          .then(r => {
            const result = r.data.features[0]
            // if found address
            if (result.place_name.includes("Fernelmont") && result.text != "Fernelmont") {
              const address = (result.address ? result.address + ", " : "") + result.text

              // if the adress is different that the one requested, ask the user
              if (address != this.activity.address) {
                let checkaddress = confirm(`Veux-tu dire "${address}"?`);

                if (checkaddress == true) {
                  this.errorAddress = ""
                  this.activity.address = address
                } else {
                  this.errorAddress = "Adresse inconnue."
                }
              } else {
                // save coordinates
                this.errorAddress = ""

                const updatedActivity = {
                  id: this.activity._id,
                  title: this.activity.title,
                  type: this.activity.type,
                  picture: this.url,
                  starting_date: this.activity.starting_date,
                  ending_date: this.activity.ending_date,
                  starting_time: this.activity.starting_time,
                  ending_time: this.activity.ending_time,
                  address: this.activity.address,
                  description: this.activity.description,
                  information: !this.activity.information,
                  email: this.email,
                  gsm: this.gsm,
                  location: result.geometry
                }
                this.updateActivity(updatedActivity)
              }
            } else {
              this.errorAddress = "Adresse inconnue."
            }
          })
      } else {
        const updatedActivity = {
          id: this.activity._id,
          title: this.activity.title,
          type: this.activity.type,
          picture: this.url,
          starting_date: this.activity.starting_date,
          ending_date: this.activity.ending_date,
          starting_time: this.activity.starting_time,
          ending_time: this.activity.ending_time,
          address: this.activity.address,
          description: this.activity.description,
          information: !this.activity.information
        }
        this.updateActivity(updatedActivity)
      }
    },
    urlSubmited (url) {
      this.url = url
    },
    addressHasChanged () {
      this.addressChange = true
    }
  }
}
</script>
