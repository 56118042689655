import axios from 'axios'
import router from '@/router'
const state = {
  activities: [],
  activity: {}
}

const getters = {
  allActivities: state => state.activities
}

const actions = {
  async addUserActivity ({ commit }, activity) {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    const response = await axios.post(
      `/api/activity/add_activity?${query}`, activity
    )

    commit('new', response.data)
    router.push({ name: 'Home' })
  },

  async fetchActivities ({ commit }) {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    const response = await axios.post(
      `/api/activity?${query}`
    )

    commit('setActivities', response.data)
  },
  async addActivity ({ commit }, activity) {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    const response = await axios.post(
      `/api/activity/add_activity?${query}`, activity
    )
    commit('newActivity', response.data)
    router.push({ name: 'AdminPanelActivities' })
  },
  async deleteActivity ({ commit }, id) {
    if(confirm("Est-tu sûr de vouloir supprimer cette activité ?") == true){
      const query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.delete(`/api/activity/${id}?${query}`)
  
      commit('remove', id)
      location.reload()
    }
  },

  async filterActivities ({ commit }, e) {
    // Get selected number
    const limit = parseInt(e.target.options[e.target.options.selectedIndex].innerText)
    const response = await axios.get(`/api/activity?_limit=${limit}`)

    commit('setActivities', response.data)
  },
  async updateActivity ({ commit }, updActivity) {
    if(confirm("Est-tu sûr de vouloir modifier cette activité?") == true){
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      const response = await axios.put(
        `/api/activity/${updActivity.id}?${query}`, updActivity)

      commit('updateActivity', response.data)
      router.push({ name: 'AdminPanelActivities' })
    }
  }
}

const mutations = {
  setActivities: (state, activities) => (state.activities = activities),
  newActivity: (state, activity) => state.activities.push(activity),
  remove: (state, id) =>
    (state.activities = state.activities.filter(activity => activity.id !== id)),
  updateActivity: (state, updActivity) => {
    const index = state.activities.findIndex(activity => activity.id === updActivity.id)
    if (index !== -1) {
      state.activities.splice(index, 1, updActivity)
    }
  },
  new: (state, activity) => state.activities.push(activity)
}

export default {
  state,
  getters,
  actions,
  mutations
}
