<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <h1 class="text-2xl font-semibold text-gray-900">Quizz</h1>
    <button @click="this.$router.push('/admin/quiz/add')" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Créer
    </button>
  </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-4">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Question
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Réponse
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Publié
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200" >
                <tr v-for="quiz in allQuizz" :key="quiz.id">
                  <td class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    {{ quiz.question }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-green-700">
                    {{ quiz.answers[quiz.correctAnswer] }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-left">
                    <router-link :to="{name: 'AdminPanelEditQuiz', params: { id: quiz._id }}" >📝</router-link> |
                    <a class="cursor-pointer" @click="deleteQuiz(quiz._id)">🗑️</a>
                  </td>
                  <td  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <label :for="'toogleD'+quiz._id" class="flex items-center cursor-pointer">
                      <!-- toggle -->
                      <div class="relative">
                        <!-- input -->
                        <input v-if="quiz.quizStatus == true" @click='toggleQuizStatus(quiz._id)' :id="'toogleD'+quiz._id" type="checkbox" class="sr-only"  checked/>
                        <input v-else @click='toggleQuizStatus(quiz._id)' :id="'toogleD'+quiz._id" type="checkbox" class="sr-only" />
                        <!-- line -->
                        <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                        <!-- dot -->
                        <div  class="dot absolute w-6 h-6 bg-red-600 rounded-full shadow -left-1 -top-1 transition"></div>
                      </div>
                    </label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  mapActions } from 'vuex'
import axios from "axios";
import moment from "moment";

export default {
  data () {
    return {
      allQuizz: [],
      errors: [],
      QuizStatus: '',
    }
  },

  // computed: mapGetters(['allQuizz']),
  async created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    await axios.get(`/api/quiz?${query}`)
      .then(r => {
        this.allQuizz = r.data.quiz
        this.allQuizz.forEach(function (item) {
          item.active = false
        })
      })
      .catch((e) => {
        localStorage.removeItem('token')
        this.$router.push('/login')
      })
  },
  methods: {
    ...mapActions([ 'deleteQuiz']),
    formatDateonly (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    toggleQuizStatus (_id) {
      this.QuizStatus = !this.QuizStatus
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      axios.get(`/api/quiz/QuizStatus/${_id}?${query}`)
        .then(response => {
          this.quizStatus = response.data
        })
        .catch(function (error) {
          console.error(error)
        })
    }
  },
}
</script>

<style>

/* Toggle */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

</style>
